/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-nav {
  background-color: #f8f9fa;
  padding: 1rem;
}

.app-nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
}

.app-nav li {
  margin: 0 0.5rem;
}

.app-nav a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.app-main {
  flex-grow: 1;
  padding: 2rem;
}

.home {
  text-align: center;
}

.translation-options {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}

.option {
  background-color: #f1f3f5;
  border-radius: 8px;
  padding: 1rem;
  text-decoration: none;
  color: #333;
  width: 25%;
  transition: transform 0.2s, box-shadow 0.2s;
}

.option:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .translation-options {
    flex-direction: column;
    align-items: center;
  }

  .option {
    width: 80%;
    margin-bottom: 1rem;
  }
}